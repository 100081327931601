<script>
import { app } from '@/main'
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'
import { formatSlug } from '@/support/utils/stringHelper'
import { isValidColor } from '@/support/utils/validHexColor'
import { solutionTypeFormatter } from '@/support/utils/solutionTypeFormatter'

const TRANSLATION = {
  description: {
    description: app.$t('global:form.thematicPages.description')
  }
}

const RICH_TEXT_EDITOR_TOOLBAR = [
  [
    'bold',
    'italic',
    'underline',
    'strike'
  ],
  [ 'clean' ],
  [ 'link' ]
]

const isValidURL = function (event) {
  if (!event) return true
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

  return !!pattern.test(event)
}

export default {
  name: 'ThematicPagesManage',
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    Radio: () => import('@/components/general/Radio'),
    Upload: () => import('@/components/general/Upload'),
    Action: () => import('@/components/general/Action'),
    Divisor: () => import('@/components/general/Divisor'),
    Datatable: () => import('@/components/general/Datatable'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    Pagination: () => import('@/components/general/Pagination'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    AppMultiselect: () => import('@/components/general/AppMultiselect'),
    RichTextEditor: () => import('@/components/general/RichTextEditor'),
    FilterListSearch: () => import('@/components/general/FilterListSearch')
  },

  data () {
    return {
      activeTab: 1,
      linkToThematicPageTabActive: 0,
      linkToThematicPageTab: [
        { text: this.$t('thematicPages.manage:link.tab.1') },
        { text: this.$t('thematicPages.manage:link.tab.2') }
      ],

      yesNoOptions: [
        {
          label: this.$t('global:yes'),
          value: true
        },
        {
          label: this.$t('global:no'),
          value: false
        }
      ],

      restrictedAccessOptions: [
        {
          label: this.$t('global:unrestricted'),
          value: false
        },
        {
          label: this.$t('global:restricted'),
          value: true
        }
      ],

      paths: [],
      solutions: [],
      programs: [],
      groupList: [],
      showSolutions: false,
      showPaths: false,
      showPrograms: false,
      thematicPageTypesList: [],
      formData: {
        id: null,
        thematicPageTypeId: null,
        name: null,
        subtitle: null,
        callText: null,
        description: null,
        imageBanner: null,
        imageLogo: null,
        colorHeader: null,
        slug: null,
        colorBottom: null,
        showList: false,
        seoTitle: null,
        seoDescription: null,
        seoKeywords: null,
        restrictedAccess: false,
        solutionGroups: [],
        thematicPageSolutions: [],
        thematicPageLearningPaths: [],
        thematicPagePrograms: [],
        facebook: null,
        instagram: null,
        linkedin: null,
        twitter: null
      },

      backUrl: { name: 'thematicPages.index' },
      modalConfirm: false,
      modalLeave: false,
      modalActiveThematicPageOpen: false,
      isSlugDuplicate: false,
      formatedUrl: '',
      isLoadingMoreSolutions: false,
      isLoadingMorePaths: false,
      isLoadingMorePrograms: false,

      solutionsPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      pathsPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      groupsPagination: {
        filter: {
          active: 1
        },

        limit: 9999
      },

      programsPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      solutionInfiniteScrollId: new Date()
        .getTime(),

      pathsInfiniteScrollId: new Date()
        .getTime(),

      programsInfiniteScrollId: new Date()
        .getTime(),

      maxSteps: 3
    }
  },

  validations () {
    return {
      formData: {
        thematicPageTypeId: { required },
        name: { required },
        slug: {
          required,
          regexSlug: (event) => {
            const regexSlug = new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$')

            return regexSlug.test(event)
          },

          slugDuplicate: () => {
            return this.isSlugDuplicate
          }
        },

        subtitle: { required },
        callText: { required },
        description: { required },
        imageBanner: { required },
        imageLogo: { required },
        restrictedAccess: { required },

        solutionGroups: {
          required: requiredIf(function () {
            return this.restrictedAccess
          }),

          minGroupsLength: (value, formData) => {
            return formData.restrictedAccess ? value ? value.length > 0 : true : true
          }
        },

        colorHeader: {
          required,
          hexColorValidate: (colorHeader) => {
            return isValidColor(colorHeader)
          }
        },

        colorBottom: {
          required,
          hexColorValidate: (colorBottom) => {
            return isValidColor(colorBottom)
          }
        },

        showList: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        facebook: {
          maxLength: maxLength(250),
          validURL: isValidURL
        },

        instagram: {
          maxLength: maxLength(250),
          validURL: isValidURL
        },

        twitter: {
          maxLength: maxLength(250),
          validURL: isValidURL
        },

        linkedin: {
          maxLength: maxLength(250),
          validURL: isValidURL
        }
      }
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),
    availableSolutions () {
      return this.solutions.filter(solution => !this.formData.thematicPageSolutions.find(findSolution => solution.id === findSolution.id))
        .sort((a, b) => {
          if (a > b) return 1
          if (b > a) return -1

          return 0
        })
    },

    availablePaths () {
      return this.paths.filter(path => !this.formData.thematicPageLearningPaths.find(findPath => path.id === findPath.id))
        .sort((a, b) => {
          if (a > b) return 1
          if (b > a) return -1

          return 0
        })
    },

    availablePrograms () {
      return this.programs.filter(program => !this.formData.thematicPagePrograms.find(findProgram => program.id === findProgram.id))
        .sort((a, b) => {
          if (a > b) return 1
          if (b > a) return -1

          return 0
        })
    },

    isEditing () {
      return !!(this.$route.params.id || this.formData.id || false)
    },

    isProgramEnabled () {
      return this.activeModules.includes('program')
    }
  },

  watch: {
    'formData.thematicPageTypeId' () {
      this.updateFormattedUrl()
    },

    'formData.name' () {
      if (!this.$v.formData.slug.$dirty && !this.isEditing) {
        this.formData.slug = formatSlug(this.formData.name)
      }
    },

    'formData.slug' (event) {
      this.formData.slug = event.replace(new RegExp('[-]{2,}', 'g'), '-')

      this.debounceEvent(async () => {
        this.setFetching(true)
        const search = { slug: event }

        if (this.isEditing) {
          search.not_id = [ this.formData.id ]
        }

        const thematicPages = await this.attemptGetThematicPagesList({ filter: search })
          .then(pagination => { return pagination.data })

        this.isSlugDuplicate = !(thematicPages.length > 0)

        if (!this.isSlugDuplicate) {
          this.$v.formData.slug.$touch()
        }

        this.setFetching(false)
      }, 1000)

      this.updateFormattedUrl()
    },

    'formData.facebook' (value) {
      if (value && !value.startsWith('https://')) {
        this.formData.facebook = `https://${this.formData.facebook}`
      }
    },

    'formData.instagram' (value) {
      if (value && !value.startsWith('https://')) {
        this.formData.instagram = `https://${this.formData.instagram}`
      }
    },

    'formData.twitter' (value) {
      if (value && !value.startsWith('https://')) {
        this.formData.twitter = `https://${this.formData.twitter}`
      }
    },

    'formData.linkedin' (value) {
      if (value && !value.startsWith('https://')) {
        this.formData.linkedin = `https://${this.formData.linkedin}`
      }
    },

    'activeModules' () {
      if (this.isProgramEnabled) {
        this.linkToThematicPageTab.push({
          text: this.$t('thematicPages.manage:link.tab.3')
        })
      }
    }
  },

  created () {
    this.activeTab = this.$route.params.tab || 1

    this.setup()
      .then(() => {
        if (this.$route.params.id) {
          this.setFetching(true)

          this.attemptGetThematicPage(this.$route.params.id)
            .then(({ data }) => {
              this.formData = {
                ...this.formData,
                ...data
              }

              this.formData.thematicPageTypeId = data.thematicPageType.id

              this.formData.solutionGroups = data.groupThematicPage.map(item => {
                return {
                  text: item.group.name,
                  value: item.group.id
                }
              })

              this.formData.restrictedAccess = data.restrictedAccess
              this.fetchConnections(data.groupThematicPage)

              this.formData.thematicPageSolutions = this.formData.thematicPageSolutions && this.formData.thematicPageSolutions.map(({ solution }) => {
                return {
                  id: solution.id,
                  name: solution.name
                }
              })

              this.formData.thematicPageLearningPaths = this.formData.thematicPageLearningPaths && this.formData.thematicPageLearningPaths.map(({ learningPath }) => {
                return {
                  id: learningPath.id,
                  name: learningPath.name
                }
              })

              this.formData.thematicPagePrograms = this.formData.thematicPagePrograms && this.formData.thematicPagePrograms.map(({ program }) => {
                return {
                  id: program.id,
                  name: program.name
                }
              })

              this.showSolutions = !!(this.formData.thematicPageSolutions && this.formData.thematicPageSolutions.length > 0)
              this.showPaths = !!(this.formData.thematicPageLearningPaths && this.formData.thematicPageLearningPaths.length > 0)
              this.showPrograms = !!(this.formData.thematicPagePrograms && this.formData.thematicPagePrograms.length > 0)
            })
            .catch(() => {
              this.setFeedback({ message: this.$t('thematicPages.load:fetch.error') })
              this.$router.push({ name: 'thematicPages.index' })
            })
            .finally(() => {
              this.setFetching(false)
            })
        }
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetThematicPage',
      'attemptGetThematicPagesList',
      'attemptCreateThematicPage',
      'attemptUpdateThematicPage',
      'attemptGetSolutionsList',
      'attemptGetLearningPathsList',
      'attemptGetProgramsList',
      'attemptGetGroupsList',
      'attemptToggleActiveThematicPage',
      'attemptGetThematicPageTypesList'
    ]),

    updateFormattedUrl () {
      if (this.formData.thematicPageTypeId) {
        const thematicPageType = this.thematicPageTypesList.filter(item => item.value === this.formData.thematicPageTypeId)[0].text.toLowerCase()

        this.formatedUrl = `${process.env.VUE_APP_PORTAL_URL}${thematicPageType}/${this.formData.slug || ''}`
      } else {
        this.formatedUrl = `${process.env.VUE_APP_PORTAL_URL}pagina-tematica/${this.formData.slug || ''}`
      }
    },

    prevPage () {
      this.submit()
        .then(() => {
          if (this.activeTab > 1) this.activeTab -= 1
        })
    },

    nextPage () {
      this.submit()
        .then(() => {
          this.activeTab += 1

          if (this.isMaxStep(this.activeTab)) {
            if (this.solutionsPagination.query.name) {
              this.solutionsPagination.query.name = null
              this.solutionsPagination.page = 1
              this.fetchSolutionsData()
            }

            if (this.pathsPagination.query.name) {
              this.pathsPagination.query.name = null
              this.pathsPagination.page = 1
              this.fetchPathsData()
            }

            if (this.programsPagination.query.name) {
              this.programsPagination.query.name = null
              this.programsPagination.page = 1
              this.fetchProgramsData()
            }
          }
        })
    },

    formatFormData () {
      const data = {}

      data.id_thematic_page = this.formData.id
      data.thematic_page_type_id = this.formData.thematicPageTypeId
      data.image_logo = this.formData.imageLogo
      data.image_banner = this.formData.imageBanner
      data.name = this.formData.name
      data.subtitle = this.formData.subtitle
      data.call_text = this.formData.callText
      data.description = this.formData.description
      data.show = this.formData.showList
      data.color_bottom = this.formData.colorBottom
      data.color_header = this.formData.colorHeader
      data.slug = this.formData.slug
      data.groups = this.formData.restrictedAccess ? this.formData.solutionGroups.filter(item => item.value !== 'all').map(item => item.value) : []
      data.restricted_access = this.formData.restrictedAccess ? 1 : 0
      data.solution = this.formData.thematicPageSolutions.map(solution => solution.id)
      data.learning_path = this.formData.thematicPageLearningPaths.map(path => path.id)
      data.program = this.formData.thematicPagePrograms.map(program => program.id)
      data.seoTitle = this.formData.seoTitle
      data.seoDescription = this.formData.seoDescription
      data.seoKeywords = this.formData.seoKeywords
      data.facebook = this.formData.facebook
      data.instagram = this.formData.instagram
      data.linkedin = this.formData.linkedin
      data.twitter = this.formData.twitter

      return data
    },

    submitCreation () {
      this.setFetching(true)

      return this.attemptCreateThematicPage(this.formatFormData())
        .then(({ data }) => {
          this.formData.id = data.id
          this.fetchConnections(data.groupThematicPage)
          this.setFeedback({ message: this.$t('thematicPages:submit.create.success') })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)

      return this.attemptUpdateThematicPage(this.formatFormData())
        .then((data) => {
          this.fetchConnections(data.groupThematicPage)
          this.setFeedback({ message: this.$t('thematicPages:submit.update.success') })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    clearLinkedSolutions (event) {
      if (event) return

      this.formData.thematicPageSolutions = []
    },

    addToLinkedSolution (solutionId) {
      this.formData.thematicPageSolutions
        .push(this.solutions.find(solution => solution.id === solutionId))
    },

    removeLinkedSolution (solutionId) {
      this.formData.thematicPageSolutions = this.formData.thematicPageSolutions.filter(solution => solution.id !== solutionId)
    },

    clearLinkedPaths (event) {
      if (event) return

      this.formData.thematicPageLearningPaths = []
    },

    addToLinkedPath (pathId) {
      this.formData.thematicPageLearningPaths
        .push(this.paths.find(path => path.id === pathId))
    },

    removeLinkedPath (pathId) {
      this.formData.thematicPageLearningPaths = this.formData.thematicPageLearningPaths.filter(path => path.id !== pathId)
    },

    clearLinkedPrograms (event) {
      if (event) return

      this.formData.thematicPagePrograms = []
    },

    addToLinkedProgram (programId) {
      this.formData.thematicPagePrograms
        .push(this.programs.find(program => program.id === programId))
    },

    removeLinkedProgram (programId) {
      this.formData.thematicPagePrograms = this.formData.thematicPagePrograms.filter(program => program.id !== programId)
    },

    submit () {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          resolve(true)

          return this.isEditing ? this.submitUpdate() : this.submitCreation()
        } else {
          this.setFeedback({ message: this.$t('thematicPages:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    setup () {
      this.TRANSLATION = TRANSLATION
      this.RICH_TEXT_EDITOR_TOOLBAR = RICH_TEXT_EDITOR_TOOLBAR
      this.setFetching(true)

      return Promise.all([
        this.attemptGetSolutionsList(this.solutionsPagination)
          .then(pagination => {
            if (pagination.data) {
              this.solutions = []
              this.solutionsPagination.lastPage = pagination.lastPage
              this.solutionsPagination.page = pagination.actualPage

              pagination.data.forEach(element => {
                this.solutions.push({
                  name: element.name,
                  id: element.id,
                  workload: element.workload,
                  solutionType: element.solutionType
                })
              })
            }
          }),

        this.attemptGetLearningPathsList(this.pathsPagination)
          .then(pagination => {
            if (pagination.data) {
              this.paths = []
              this.pathsPagination.lastPage = pagination.lastPage
              this.pathsPagination.page = pagination.actualPage

              pagination.data.forEach(element => {
                this.paths.push({
                  name: element.name,
                  id: element.id,
                  learningPathSolutions: element.learningPathSolutions.length,
                  workload: element.workload
                })
              })
            }
          }),

        this.isProgramEnabled
          ? this.attemptGetProgramsList(this.programsPagination)
            .then(pagination => {
              if (pagination.data) {
                this.programs = []
                this.programsPagination.lastPage = pagination.lastPage
                this.programsPagination.page = pagination.actualPage

                pagination.data.forEach(element => {
                  this.programs.push({
                    name: element.name,
                    id: element.id,
                    learningPathSolutions: element.programSolutions.length,
                    workload: element.workload
                  })
                })
              }
            })
          : null,

        this.attemptGetThematicPageTypesList({
          page: 1,
          limit: 100
        })
          .then(pagination => {
            if (pagination.data) {
              pagination.data.forEach(element => {
                this.thematicPageTypesList.push({
                  text: element.name,
                  value: element.id
                })
              })

              return true
            }
          }),

        this.attemptGetGroupsList(this.groupsPagination)
          .then(pagination => {
            if (pagination.data) {
              pagination.data.forEach(element => {
                this.groupList.push({
                  text: element.name,
                  value: element.id
                })
              })

              return true
            }
          })
      ])
        .finally(() => {
          this.setFetching(false)
        })
    },

    saveAll () {
      if (this.isEditing && !this.formData.active) {
        this.modalActiveThematicPageOpen = true
      } else {
        this.submit(false)
      }
    },

    saveAllConfirm () {
      if (this.isEditing) {
        this.modalActiveThematicPageOpen = false

        this.submit(false)
          .then(() => {
            this.attemptToggleActiveThematicPage(this.formData.id)
              .then(() => {
                this.setFeedback({ message: this.$t('thematicPages:feedback.toggle.activate.success') })
              })
          })
      }
    },

    saveAllCancel () {
      this.modalActiveThematicPageOpen = false
      this.submit(false)
    },

    searchSolution (search) {
      this.solutionsPagination.query.name = search
      this.solutionsPagination.page = 1
      this.solutionsPagination.lastPage = null
      this.solutionsPagination.query.only_name = true
      this.fetchSolutionsData()
    },

    loadMoreSolutions () {
      this.isLoadingMoreSolutions = true

      if (this.solutionsPagination.lastPage) {
        if (this.solutionsPagination.lastPage > this.solutionsPagination.page) {
          this.solutionsPagination.page += 1
        } else {
          this.isLoadingMoreSolutions = false

          return
        }
      }

      this.fetchSolutionsData(true)
        .then(() => {
          this.isLoadingMoreSolutions = false
        })
    },

    async fetchSolutionsData (isLoadMore = false) {
      this.setFetching(true)

      this.solutionsPagination.filter.thematic_page_id = this.formData.id

      return this.attemptGetSolutionsList(this.solutionsPagination)
        .then((pagination) => {
          this.solutionInfiniteScrollId += 1
          this.solutionsPagination.lastPage = pagination.lastPage
          this.solutionsPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.solutions = []
            }

            pagination.data.forEach(element => {
              this.solutions.push({
                name: element.name,
                id: element.id,
                workload: element.workload,
                solutionType: element.solutionType
              })
            })
          }

          this.setFetching(false)

          return pagination
        })
    },

    searchPath (search) {
      this.pathsPagination.query.name = search
      this.pathsPagination.page = 1
      this.pathsPagination.lastPage = null
      this.fetchPathsData()
    },

    loadMorePaths () {
      this.isLoadingMorePaths = true

      if (this.pathsPagination.lastPage) {
        if (this.pathsPagination.lastPage > this.pathsPagination.page) {
          this.pathsPagination.page += 1
        } else {
          this.isLoadingMorePaths = false

          return
        }
      }

      this.fetchPathsData(true)
        .then(() => {
          this.isLoadingMorePaths = false
        })
    },

    async fetchPathsData (isLoadMore = false) {
      this.setFetching(true)

      this.pathsPagination.filter.thematic_page_id = this.formData.id

      return this.attemptGetLearningPathsList(this.pathsPagination)
        .then((pagination) => {
          this.pathsInfiniteScrollId += 1
          this.pathsPagination.lastPage = pagination.lastPage
          this.pathsPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.paths = []
            }

            pagination.data.forEach(element => {
              this.paths.push({
                name: element.name,
                id: element.id,
                learningPathSolutions: element.learningPathSolutions.length,
                workload: element.workload
              })
            })
          }

          this.setFetching(false)

          return pagination
        })
    },

    getSolutionTypeFormattedName (solutionType) {
      return solutionTypeFormatter(solutionType)
    },

    searchProgram (search) {
      this.programsPagination.query.name = search
      this.programsPagination.page = 1
      this.programsPagination.lastPage = null
      this.fetchProgramsData()
    },

    loadMorePrograms () {
      this.isLoadingMorePrograms = true

      if (this.programsPagination.lastPage) {
        if (this.programsPagination.lastPage > this.programsPagination.page) {
          this.programsPagination.page += 1
        } else {
          this.isLoadingMorePrograms = false

          return
        }
      }

      this.fetchProgramsData(true)
        .then(() => {
          this.isLoadingMorePrograms = false
        })
    },

    async fetchProgramsData (isLoadMore = false) {
      this.setFetching(true)

      return this.attemptGetProgramsList(this.programsPagination)
        .then((pagination) => {
          this.programsInfiniteScrollId += 1
          this.programsPagination.lastPage = pagination.lastPage
          this.programsPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.programs = []
            }

            pagination.data.forEach(element => {
              this.programs.push({
                name: element.name,
                id: element.id,
                programSolutions: element.programSolutions.length,
                workload: element.workload
              })
            })
          }

          this.setFetching(false)

          return pagination
        })
    },

    isMaxStep (currentTab) {
      /** @TODO vve como melhorar essa verificação, atualmente fixada com this.maxSteps (talvez com o total de passos, mudando de v-if para v-show?) */
      return currentTab === this.maxSteps
    },

    fetchConnections () {
      this.setFetching(true)

      Promise.all([
        this.fetchSolutionsData(),
        this.fetchPathsData()
      ])
        .finally(() => {
          this.setFetching(false)
        })
    },

    addSolutionGroup (item) {
      if (!this.formData.solutionGroups) {
        this.formData.solutionGroups = []
      }

      if (item) {
        if (item.value === 'all') {
          this.formData.solutionGroups = this.groupList
        } else {
          this.formData.solutionGroups.push(item)
        }
      }
    },

    removeSolutionGroup (item) {
      if (item) {
        if (item.value === 'all') {
          this.formData.solutionGroups = []
        } else {
          this.formData.solutionGroups = this.formData.solutionGroups.filter(solutionGroup => solutionGroup.value !== item.value)
        }
      }
    }
  }
}
</script>

<template>
  <div class="main-content thematic-pages-create">
    <ContentHeader
      :title="isEditing ? formData.name : $t('thematicPages.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.thematicPages')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="saveAll()"
        />
      </template>
    </ContentHeader>
    <div
      v-if="activeTab === 1"
      class="main-content-inner"
    >
      <div class="center">
        <div class="thematic-pages-create-header">
          <h2 class="thematic-pages-create-title">
            {{ $t('global:form.step', {'num': 1}) }}
          </h2>
          <p
            class="thematic-pages-create-description"
            v-html="$t('thematicPages.create:header.description')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <SelectField
            v-model="formData.thematicPageTypeId"
            tabindex="0"
            :label="$t('global:form.thematicPages.type')"
            :items="thematicPageTypesList"
            :validation="$v.formData.thematicPageTypeId"
          />

          <InputField
            v-model="formData.name"
            :label="$t('global:form.thematicPages.name')"
            :validation="$v.formData.name"
            :counter="100"
          />

          <InputField
            v-model="formData.subtitle"
            :label="$t('global:form.thematicPages.slogan')"
            :validation="$v.formData.subtitle"
            :counter="250"
          />

          <InputField
            v-model="formData.slug"
            :label="$t('global:form.thematicPages.slug')"
            :validation="$v.formData.slug"
            :counter="100"
            :under-description="formatedUrl"
          />

          <FormSection :title="$t('solution.manage:field.description.restricted.access')">
            <Radio
              v-model="formData.restrictedAccess"
              :under-description="$t('thematicPages.manage:field.under.description.restricted.access')"
              :items="restrictedAccessOptions"
              horizontal
              :disabled="isEditing"
              :validation="$v.formData.restrictedAccess"
            />

            <AppMultiselect
              v-if="formData.restrictedAccess"
              v-model="formData.solutionGroups"
              :options="groupList"
              :placeholder="$t('global:form.groups.select')"
              :validation="$v.formData.solutionGroups"
              @onSelect="addSolutionGroup"
              @onRemove="removeSolutionGroup"
            />
          </FormSection>

          <InputField
            v-model="formData.callText"
            :label="$t('global:form.thematicPages.main.title')"
            :validation="$v.formData.callText"
            :counter="250"
          />

          <FormSection :title="TRANSLATION.description.description">
            <RichTextEditor
              v-model="formData.description"
              :validation="$v.formData.description"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="$t('global:upload.add.thematicPage.banner')">
            <Upload
              v-model="formData.imageBanner"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:upload.add.thematicPage.banner.description')"
              cropper
              :width="2740"
              :height="942"
              :preview="0.175"
              :validation="$v.formData.imageBanner"
            />
          </FormSection>

          <FormSection :title="$t('global:upload.add.thematicPage.background')">
            <Upload
              v-model="formData.imageLogo"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:upload.add.thematicPage.background.description')"
              cropper
              :width="327"
              :height="327"
              :preview="0.6"
              :validation="$v.formData.imageLogo"
            />
          </FormSection>

          <FormSection :title="$t('global:form.thematicPages.socials')">
            <InputField
              v-model="formData.facebook"
              :label="$t('global:form.thematicPages.socials.facebook')"
              :validation="$v.formData.facebook"
              :counter="250"
              type="url"
              :under-description="$t('global:form.thematicPages.socials.facebook.url')"
            />

            <InputField
              v-model="formData.instagram"
              :label="$t('global:form.thematicPages.socials.instagram')"
              :validation="$v.formData.instagram"
              :counter="250"
              type="url"
              :under-description="$t('global:form.thematicPages.socials.instagram.url')"
            />

            <InputField
              v-model="formData.linkedin"
              :label="$t('global:form.thematicPages.socials.linkedin')"
              :validation="$v.formData.linkedin"
              :counter="250"
              type="url"
              :under-description="$t('global:form.thematicPages.socials.linkedin.url')"
            />

            <InputField
              v-model="formData.twitter"
              :label="$t('global:form.thematicPages.socials.twitter')"
              :validation="$v.formData.twitter"
              :counter="250"
              type="url"
              :under-description="$t('global:form.thematicPages.socials.twitter.url')"
            />
          </FormSection>
          <!--
 <form-section :title="$t('global:upload.add.thematicPage.logo')">
            <upload
              :icon="'image-multiple'"
              :label="$t('global:upload.add.image')"
              :description="$t('global:upload.add.thematicPage.logo.description')"
              cropper
              :width="327"
              :height="327"
              :preview="0.6"
              v-model="formData.imageLogo"
              :validation="$v.formData.imageLogo"
            >
            </upload>
          </form-section>
          <FormSection :title="$t('global:form.show.on.list')">
            <Radio
              v-model="formData.showList"
              :under-description="$t('global:form.show.on.list.under-description')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.showList"
            />
          </FormSection>
-->
          <FormSection :title="$t('global:thematicPages.background.colors')">
            <div class="hex-under-description">
              <label class="form-input-subtext">{{ $t('global:thematicPages.background.colors.under-description') }}</label>
            </div>

            <InputField
              v-model="formData.colorHeader"
              :label="$t('global:thematicPages.background.color.1')"
              :validation="$v.formData.colorHeader"
            />

            <InputField
              v-model="formData.colorBottom"
              :label="$t('global:thematicPages.background.color.2')"
              :validation="$v.formData.colorBottom"
            />
          </FormSection>
        </form>
      </div>
    </div>
    <div
      v-if="activeTab === 2"
      class="main-content-inner"
    >
      <div class="center">
        <div class="thematic-pages-create-header">
          <h2 class="thematic-pages-create-title">
            {{ $t('global:form.step', {'num': 2}) }}
          </h2>
          <p
            class="thematic-pages-create-description"
            v-html="$t('thematicPages.create:header.description.2')"
          />
          <span class="under-description">{{ $t('thematicPages.create:header.description.2.under-description') }}</span>
        </div>
        <form
          class="form"
          @submit.prevent="submit()"
        >
          <InputField
            v-model="formData.seoTitle"
            :label="$t('global:form.thematicPages.seo.title')"
          />
          <InputField
            v-model="formData.seoDescription"
            :label="$t('global:form.thematicPages.seo.description')"
          />
          <InputField
            v-model="formData.seoKeywords"
            :label="$t('global:form.thematicPages.seo.keywords')"
          />
        </form>
      </div>
    </div>
    <div
      v-if="activeTab === 3"
      class="main-content-inner"
    >
      <div class="center">
        <div class="thematic-pages-create-header">
          <h2 class="thematic-pages-create-title">
            {{ $t('global:form.step', {'num': 3}) }}
          </h2>

          <p
            class="thematic-pages-create-description"
            v-html="$t(`thematicPages.create:header.description.${isProgramEnabled ? '3' : '4'}`)"
          />
        </div>
        <form
          class="center"
          @submit.prevent="submit()"
        >
          <Tabs
            slot="tabs"
            :links="linkToThematicPageTab"
            :index-active="linkToThematicPageTabActive"
            @tabChange="linkToThematicPageTabActive = $event"
          />

          <div
            v-show="linkToThematicPageTabActive === 0"
            class="link-selection"
          >
            <FormSection :title="$t('show.in.Solutions')">
              <Radio
                v-model="showSolutions"
                :items="yesNoOptions"
                horizontal
                @input="clearLinkedSolutions()"
              />
            </FormSection>
            <div
              v-if="showSolutions"
              class="link-table"
            >
              <div class="linkable-items">
                <Datatable
                  :is-loading-more="isLoadingMoreSolutions"
                  :items="availableSolutions"
                  hide-horizontal-line
                  has-infinite-scroll
                  has-fade
                  empty-message="no_result"
                  @loadMore="loadMoreSolutions"
                >
                  <template slot="thead">
                    <th
                      class="th"
                      width="300"
                    >
                      {{ $t('solutions.list.link:datatable.header.1') }}
                    </th>
                  </template>
                  <template slot="tsearch">
                    <FilterListSearch
                      slot="search"
                      :prepend-label="$t('global:form.solution-search')"
                      :hide-error-details="true"
                      class="filter-search"
                      @search="searchSolution"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      :key="props.item.id"
                      @click="addToLinkedSolution(props.item.id)"
                    >
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                        <br>
                        <span class="td-text td-small">{{ getSolutionTypeFormattedName(props.item.solutionType) }} | {{ props.item.workload }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="keyboard_arrow_right"
                          flat
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
              <Divisor orientation="vertical" />
              <div class="linked-items">
                <Datatable
                  has-fade
                  :items="formData.thematicPageSolutions"
                  hide-horizontal-line
                >
                  <template slot="thead">
                    <th class="th">
                      {{ $t('solutions.list.link:datatable.header.2') }}
                    </th>
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr :key="props.item.id">
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="close"
                          flat
                          @click="removeLinkedSolution(props.item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
          <div
            v-show="linkToThematicPageTabActive === 1"
            class="link-selection"
          >
            <FormSection :title="$t('show.in.Paths')">
              <Radio
                v-model="showPaths"
                :items="yesNoOptions"
                horizontal
                @input="clearLinkedPaths()"
              />
            </FormSection>
            <div
              v-if="showPaths"
              class="link-table"
            >
              <div class="linkable-items">
                <Datatable
                  :is-loading-more="isLoadingMorePaths"
                  :items="availablePaths"
                  hide-horizontal-line
                  has-infinite-scroll
                  has-fade
                  empty-message="no_result"
                  @loadMore="loadMorePaths"
                >
                  <template slot="thead">
                    <th
                      class="th"
                      width="300"
                    >
                      {{ $t('paths.list:datatable.header.1') }}
                    </th>
                  </template>
                  <template slot="tsearch">
                    <FilterListSearch
                      slot="search"
                      :prepend-label="$t('global:form.track-search')"
                      :hide-error-details="true"
                      class="filter-search"
                      @search="searchPath"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      :key="props.item.id"
                      @click="addToLinkedPath(props.item.id)"
                    >
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                        <br>
                        <span class="td-text td-small">
                          {{ props.item.learningPathSolutions }} {{ props.item.learningPathSolutions === 1
                            ? 'Solução'
                            : 'Soluções' }} | {{ props.item.workload ? props.item.workload : '0h' }}
                        </span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="keyboard_arrow_right"
                          flat
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
              <Divisor orientation="vertical" />
              <div class="linked-items">
                <Datatable
                  has-fade
                  :items="formData.thematicPageLearningPaths"
                  hide-horizontal-line
                >
                  <template slot="thead">
                    <th class="th">
                      {{ $t('paths.list:datatable.header.2') }}
                    </th>
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr :key="props.item.id">
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="close"
                          flat
                          @click="removeLinkedPath(props.item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
          <div
            v-show="isProgramEnabled && linkToThematicPageTabActive === 2"
            class="link-selection"
          >
            <FormSection :title="$t('show.in.Programs')">
              <Radio
                v-model="showPrograms"
                :items="yesNoOptions"
                horizontal
                @input="clearLinkedPrograms()"
              />
            </FormSection>
            <div
              v-if="showPrograms"
              class="link-table"
            >
              <div class="linkable-items">
                <Datatable
                  :is-loading-more="isLoadingMorePrograms"
                  :items="availablePrograms"
                  hide-horizontal-line
                  has-infinite-scroll
                  has-fade
                  empty-message="no_result"
                  @loadMore="loadMorePrograms"
                >
                  <template slot="thead">
                    <th
                      class="th"
                      width="300"
                    >
                      {{ $t('programs.list:datatable.path.header.1') }}
                    </th>
                  </template>
                  <template slot="tsearch">
                    <FilterListSearch
                      slot="search"
                      :prepend-label="$t('global:form.program-search')"
                      :hide-error-details="true"
                      class="filter-search"
                      @search="searchProgram"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      :key="props.item.id"
                      @click="addToLinkedProgram(props.item.id)"
                    >
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                        <br>
                        <span class="td-text td-small">
                          {{ props.item.programSolutions }} {{ props.item.programSolutions === 1
                            ? 'Solução'
                            : 'Soluções' }} | {{ props.item.workload ? props.item.workload : '0h' }}
                        </span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="keyboard_arrow_right"
                          flat
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
              <Divisor orientation="vertical" />
              <div class="linked-items">
                <Datatable
                  has-fade
                  :items="formData.thematicPagePrograms"
                  hide-horizontal-line
                >
                  <template slot="thead">
                    <th class="th">
                      {{ $t('programs.list:datatable.path.header.2') }}
                    </th>
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr :key="props.item.id">
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="close"
                          flat
                          @click="removeLinkedProgram(props.item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <Pagination
      class="center"
      :active-first-last="false"
      :active-page="activeTab"
      :page-count="3"
      @nextPage="nextPage()"
      @previousPage="prevPage()"
    />
    <ModalConfirm
      :active="modalActiveThematicPageOpen"
      :title="$t('thematicPages.list:datatable.header.1')"
      :description="$t('thematicPages:feedback.confirm.activate')"
      @confirmAction="saveAllConfirm"
      @cancelAction="saveAllCancel"
    />
  </div>
</template>

<style>
.thematic-pages-create .form {
  max-width: 480px;
  margin: 0 auto;
}
.thematic-pages-create-header {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.thematic-pages-create-title {
  font-family: 'Quicksand';
  font-size: 2.4em;
  font-weight: bold;
  color: #005EB8;
  line-height: 30px;
  margin-bottom: 5px;
}

.thematic-pages-create-description {
  font-family: 'Quicksand';
  font-size: 1.8em;
  color: #005EB8;
  line-height: 30px;
  margin-bottom: 0;
}
.link-selection,
.link-selection .form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-selection .link-table > div {
  width: 50%;
}

.link-table {
  display: flex;
  flex-direction: row;
}

.thematic-pages-create-header + .center .tabs {
  display: flex;
  justify-content: center;
  margin: 65px 0 80px;
}

.under-description {
  display: inline-block;
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  padding: 5px 0;
  max-width: 480px;
  text-transform: none;
}

.hex-under-description {
  margin-bottom: 10px;
}

</style>
